import React, { useEffect, useState } from "react"
import MenuAppBar from "../../Components/Navbar/navbar"
import { AiOutlineCloudUpload } from "react-icons/ai"
import { RiUploadCloudFill } from "react-icons/ri"
import { Container, Row, Col, Modal, Button } from "react-bootstrap"
import FirebaseApp from "./../../config/firebase"
import { navigate } from "gatsby" //import navigate from gatsby

import "./index.css"

let database = FirebaseApp.database().ref("/")

export default function UploadChalan() {
  const [approved, setapproved] = React.useState([])
  const [url, seturl] = useState("")
  const [course, setcourse] = useState("")
  const [user, setuser] = useState("")

  useEffect(() => {
    let arr = []
    FirebaseApp.auth().onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        database
          .child("students/" + user.uid + "/" + "Applied/")
          .on("child_added", res => {
            let data = res.val()
            if (!data.approved) {
              arr.push(data)
              setapproved(arr)
              setuser(user.uid)
            }
          })
      } else {
        navigate("/")
      }
    })
  }, [])

  const getImageURL = async e => {
    var uid = localStorage.getItem("user")
    uid = JSON.parse(uid)
    let imageName = e.target.files[0].name
    let ref = FirebaseApp.storage().ref("/").child(`uploadChalan/${uid.uid}`)
    await ref.put(e.target.files[0])
    ref.getDownloadURL().then(url => {
      seturl(url)
    })
  }

  const uploadChalan = () => {
    database
      .child("Registration/" + `${user + course}/`)
      .update({ approved: "Pending", PaidChalan: url })
    // database.child("students/" + user + "/" + "Applied/" + `${user + course}/`).update({ approved: "Pending", PaidChalan: url })
    navigate("/courses") //navigate to edit page
  }

  return (
    <div className="__uploadChalanMainDiv">
      <MenuAppBar />
      <p className="chalanSlipTitle">Upload Chalan Slip</p>
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} xs={7} md={6} sm={6} xl={6}>
            <div className="_selectCourseDiv">
              <select
                className="_selectCourse"
                onChange={e => setcourse(e.target.value)}
              >
                <option>Select Your Course</option>
                {approved.length !== 0
                  ? approved.map((val, i) => {
                      return <option> {val.course}</option>
                    })
                  : null}
              </select>
            </div>
          </Col>
          <Col lg={3} xs={5} md={3} sm={6} xl={2}>
            <div className="_uploadBtnDiv">
              <button
                className="_uploadBtn"
                disabled={url !== "" && course !== "" ? false : true}
                onClick={uploadChalan}
              >
                <RiUploadCloudFill size="20" /> Upload
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="_uploadChalanDiv">
        <label class="custom-file-upload">
          <input type="file" onChange={getImageURL} />
          {url === "" ? (
            <>
              {" "}
              <AiOutlineCloudUpload size="40" color="rebeccapurple" />{" "}
              <span>Choose Chalan Slip</span>
              <br />
            </>
          ) : (
            <img src={url} style={{ height: 200, width: 300 }} />
          )}
        </label>
      </div>
    </div>
  )
}
